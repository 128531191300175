/* eslint-disable react/jsx-no-undef */

import React from 'react';
import Head from 'next/head';
import LoadingScreen from '../components/LoadingScreen';

import '../styles/bootstrap.min.css';
import '../styles/all.min.css';
import '../styles/simple-line-icons.css';
import '../styles/slick.css';
import '../styles/animate.css';
import '../styles/magnific-popup.css';
import '../styles/style.css';
import '../styles/rotating-text.css';

function MyApp({ Component, pageProps }) {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(function () {
      setLoading(false);
    }, 500);
  }, []);

  if (loading) return <LoadingScreen />;
  return (
    <React.Fragment>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <Component {...pageProps} />
    </React.Fragment>
  );
}

export default MyApp;
