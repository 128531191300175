// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const environment = process.env.NEXT_PUBLIC_SENTRY_ENV || 'local';
const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN || '';
const debug = process.env.NEXT_PUBLIC_SENTRY_DEBUG === 'debug' || false;
const tracesSampleRate = process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE || 1.0;

Sentry.init({
  dsn,
  environment,
  tracesSampleRate,
  debug,
});
